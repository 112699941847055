import React, { useState } from "react";
import { Grid } from "@mui/material";
import "./FAQ.css";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const faqData = [
    {
      question: "Who can attend?",
      answer:
        "ConstructorHack! 2024 is open is open to students (from high school students over 16 years of age to post-docs) and those that have graduated from university in the past twelve months. To participate you must accept the MLH Code Of Conduct.",
    },
    {
      question: "Can I attend even if I don’t know how to code?",
      answer:
        "Yes, you can also participate even if you are not a coder. Participation is not only about coding, e.g. graphic designers can be essential to teams by making the hack look more professional. Providing ideas for projects also counts as participation. In addition, there will also be mentors willing to help you to learn to code at the event if you are interested.",
    },
    {
      question: "How do teams work?",
      answer: "You can work in teams of up to 5 people.",
    },
    {
      question: "What will I eat?",
      answer:
        "We’ve got you covered. We’ll take care of providing meals at the event from breakfast on Saturday to Brunch on Sunday. If you have dietary restrictions, we’ll try our best to accommodate them.",
    },
    {
      question: "What should I bring",
      answer:
        "Bring your laptop along with you, and all tech you think you’ll need or use. We’ll take care of the food, beverages, stickers, and much more. Feel free to bring your pillows, toiletries, headphones, etc.",
    },
    {
      question: "What about issues of intellectual property?",
      answer:
        "Everything you create belongs to you. We don’t claim any rights on your Intellectual property or the intellectual property generated by you during the hackathon or outside the hackathon. However, we reserve the right to use your submissions for promotional purposes of ConstructorHack!, see the Terms and Conditions for details.",
    },
    {
      question: "How do I apply?",
      answer:
        "Simply head to our registration portal. Convince us why you are amazing and we will hopefully see you in Bremen in November! We’ll accept applicants on a rolling basis.",
    },
    {
      question: "What if I don't have a team?",
      answer:
        "If you don’t have a team now, don’t worry! We’ll have team formation and ideation events geared towards helping you find people to work with throughout the event.",
    },
    {
      question: "What is the application process like?",
      answer:
        "We are looking for a diverse group of hackers at our hackathon. For this we will ask you a couple of questions in the application. That said, we are looking for anyone who is creative or just simply passionate for technology.",
    },
    {
      question: "Where will I sleep?",
      answer:
        "Hacking all night can get tiring. We’ll have a few bean bags and air mattresses in a quiet room for you to nap at the venue.",
    },
    {
      question: "What about hardware hacks?",
      answer:
        "jacobsHack! is an MLH hackathon. Major League Hacking generously provides us with their hardware lab that includes a variety of hardware including and not limited to Arduino, Myo armband, Muse headband, Leap Motion, Spark Core, pebble, and Oculus.",
    },
    {
      question: "How much does attending the hackathon cost?",
      answer:
        "Admission is free! We will provide food, beverages, and swag throughout the event.",
    },
  ];

  return (
    <div className="FAQ-section">
      <h1>FAQ</h1>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {faqData.slice(0, Math.ceil(faqData.length / 2)).map((faq, index) => (
            <div key={index}>
              <div
                className={`faq-question ${
                  index === activeIndex ? "active" : ""
                }`}
                onClick={() => handleAccordionClick(index)}
              >
                {faq.question}
              </div>
              {index === activeIndex && (
                <div className="faq-answer">{faq.answer}</div>
              )}
            </div>
          ))}
        </Grid>
        <Grid item xs={12} sm={6}>
          {faqData.slice(Math.ceil(faqData.length / 2)).map((faq, index) => (
            <div key={index}>
              <div
                className={`faq-question ${
                  index + Math.ceil(faqData.length / 2) === activeIndex
                    ? "active"
                    : ""
                }`}
                onClick={() =>
                  handleAccordionClick(index + Math.ceil(faqData.length / 2))
                }
              >
                {faq.question}
              </div>
              {index + Math.ceil(faqData.length / 2) === activeIndex && (
                <div className="faq-answer">{faq.answer}</div>
              )}
            </div>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default FAQ;
